import Lottie from 'react-lottie';
import Link from 'next/link';

import Layout from '@app/layouts/full-page';
import SEO from '@app/components/seo';
import GoogleAnalytics from '@app/components/google-analytics';

import animationData from '../lottie/clipboard-animation.json';

export default function Custom404(): JSX.Element {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <div className="flex flex-col relative p-0 text-center justify-center h-screen lg:p-0">
        <div>
          <Lottie options={defaultOptions} height={250} width={250} />
          <h2 className="text-white mb-6 my-5 font-bold text-7xl md:text-8xl">404</h2>
          <h3 className="font-bold text-gray-200 text-2xl md:text-5xl px-10 md:px-0 my-5 mx-auto md:w-1/2 leading-tight md:leading-tight">
            Oops... We can't seem to find the page you're looking for.
          </h3>
          <Link href="/">
            <button
              type="button"
              style={{ width: 210 }}
              className="btn btn--blue mx-auto outline-none my-5 focus:outline-none">
              Go back to homepage
            </button>
          </Link>
        </div>
      </div>
      <GoogleAnalytics />
    </Layout>
  );
}
