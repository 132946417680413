import React, { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children?: ReactNode;
};

const Layout = ({ children }: Props): JSX.Element => (
  <div className="mh-screen w-screen flex align-middle justify-center flex-col">
    {children}
    <ToastContainer />
  </div>
);

export default Layout;
